import React, { useState } from 'react'

function Hugo() {
  const [inputText, setInputText] = useState('')
  const [loading, setLoading] = useState(false)
  const [tweet, setTweet] = useState(null)
  const url = 'https://minters-server.herokuapp.com/hugo/tweet'

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)

    // Create a request payload with the form data
    const payload = { text: inputText }

    // Make a POST request to the desired URL
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        setTweet(data.tweet)
        setInputText('')
        setLoading(false)
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  const handleInputChange = event => {
    setInputText(event.target.value)
  }

  return (
    <div className='vh-100 vw-100 player-2'>
      <div className='w-100 h-100 bg-hugo-blue d-flex flex-column align-items-center justify-content-center p-2'>
        {tweet ? (
          <div className='nes-balloon from-left'>
            <p
              style={{ whiteSpace: 'pre-line' }}
              className='text-black text-center'
            >
              {tweet}
            </p>
          </div>
        ) : (
          <div className='nes-balloon from-left'>
            <p
              style={{ whiteSpace: 'pre-line' }}
              className='text-black text-center'
            >
              {`enter a subject...
                I'll write a hauku
                about it.`}
            </p>
          </div>
        )}
        <img
          className='hugo-pic'
          src='https://minters.s3.us-west-2.amazonaws.com/hugo.gif'
          alt='hugo'
        />
        <form className='d-flex flex-column' onSubmit={handleSubmit}>
          <input
            className='bg-hugo-blue text-white  hugo-input mb-3 p-2'
            type='text'
            value={inputText}
            onChange={handleInputChange}
          />
          <button className='btn btn-outline-hugo-yellow' type='submit'>
            Write Haiku
          </button>
        </form>
        {tweet && (
          <a
            className='w-100 d-flex align-items-center justify-content-center mt-3'
            href='https://twitter.com/haikuHugo'
          >
            <button className='w-100 btn btn-hugo-yellow twit'>
              View Haiku Hugo
            </button>
          </a>
        )}
        {loading && <p className='text-white'>... formulating tweet</p>}
      </div>
    </div>
  )
}
export default Hugo

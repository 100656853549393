import { useState, useEffect } from 'react'
import TV from '../../components/TV'
import './index.css'
import Logo from '../../svg/adzwtf'

const TVPage = () => {
  const [show, setShow] = useState(false)
  const [color, setColor] = useState(0)
  const colors = [
    '#FFFFFF',
    '#FF6EC7',
    '#39FF14',
    '#1B03A3',
    '#CCFF00',
    '#000000',
    '#FF5F1F',
    '#9400D3',
    '#FF073A',
    '#00FFFF',
    '#BFFF00',
    '#FF00FF',
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setColor(prevIndex => (prevIndex + 1) % colors.length)
    }, 1000) // Change color every 1000 milliseconds (1 second)

    return () => clearInterval(interval) // Clear interval on component unmount
  }, [])

  return (
    <div className='vh-100 vw-100 bg-black d-flex align-items-center justify-content-center'>
      <div className='position-absolute w-100 h-100 p-4 z8 d-flex align-items-end justify-content-end no-click'>
        <div className=' w-100 d-flex flex-column align-items-center justify-content-center pb-4'>
          <ul
            className={`bg-black text-white ${
              show ? 'show' : 'hide'
            } p-0 px-2 w-100 clickable noselect`}
          >
            <li className='w-100 text-lg-h3 mt-2'>home</li>
            <li className='w-100 text-lg-h3 mt-1'>collections</li>
            <li className='w-100 text-lg-h3 mt-1'>news</li>
          </ul>
          <div
            className='clickable noselect d-flex flex-column align-items-center justify-content-center'
            onClick={() => {
              console.log('hello there')
              // setShow(!show)
            }}
          >
            <div style={{ width: '50%' }}>
              <Logo color={colors[color]} />
            </div>
            {/* <p className='text-white text-lg-p2 mono'>... i build things</p> */}
          </div>
        </div>
      </div>
      <TV />
    </div>
  )
}

export default TVPage

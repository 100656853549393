import { useLocation } from 'react-router-dom'
const Full = () => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const text = queryParams.get('text')
  const repeat = queryParams.get('repeat')

  return (
    <div
      className={`vh-100 vw-100 d-flex align-items-center justify-content-center static-lg`}
    >
      <div className='bg-black w-50 d-flex align-items-center justify-content-center px-5 py-3  blinking-text'>
        <p className='text-lg-h1 text-white player-2 text-center d-flex align-items-center justify-content-center '>
          {text ? text : 'hello_there'}
        </p>
      </div>
    </div>
  )
}

export default Full

import { useScroll } from '@react-three/drei'
import { useState } from 'react'
import './index.css'
import { numberParser } from '../../utils/numberParser'
import { useEffect, useRef } from 'react'
import Clock from '../Clock'
import { weatherTest } from '../../api/surf'
import NewsTicker from '../StockTicker/StockTicker'

const LargeTv = ({ showMonitor = true, isLG = false }) => {
  const clickTargetRef = useRef(null)
  const [channel, setChannel] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [showHud, setShowHud] = useState(false)
  const [cc, setCC] = useState(true)
  const [hudChannel, setHudChannel] = useState(0)
  const [cp, setCp] = useState(null)
  const [prices, setPrices] = useState(null)
  const [tickerText, setTickerText] = useState('')
  const [showTicker, setShowTicker] = useState(false)
  const maxChannels = 64

  // set static channel

  const searchParams = new URLSearchParams(window.location.search)
  const staticChannel = searchParams.get('channel')

  const tuneInToChannel = c => {
    setChannel(Number(staticChannel))
    setCC(false)
  }

  useEffect(() => {
    // window.onload = () => {
    //   var iframeWindow = window.frames[0]
    //   var element = iframeWindow.document.getElementById('p8_start_button')
    //   element.click()
    // }

    if (staticChannel) {
      return tuneInToChannel(staticChannel)
    }

    const interval = setInterval(() => {
      if (clickTargetRef.current && cc) {
        clickTargetRef.current.click()
      }
    }, 30550)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (showHud) {
          const cyp = await fetch('https://minters-server.herokuapp.com/mac/')
          const b = await cyp.json()
          setCp(b)
        }
        // const test = await weatherTest()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [channel, showHud])

  function calculatePercentageChange(currentPrice, amountChanged) {
    if (currentPrice === amountChanged) {
      return 0 // To avoid division by zero when current price equals the amount changed
    }

    let originalPrice = currentPrice - amountChanged
    let percentageChange = amountChanged / originalPrice

    return percentageChange // Rounds the result to 2 decimal places
  }

  const parseToNews = d => {
    console.log('here is d::::', d)
    const { marketData, surfCheck } = d

    const s = [
      'bitcoin',
      'ethereum',
      'shiba-inu',
      'matic-network',
      'pepe',
      'dogecoin',
      'pepefork',
    ]
    let t = ''

    for (let i = 0; i < s.length; i++) {
      const { price, name, symbol, priceChange1d } = marketData.find(
        d => d.id === s[i]
      )
      const pchange = priceChange1d

      t =
        t +
        ` * ${symbol} ${
          symbol === ' PEPE' || symbol === 'SHIB' || symbol === 'PORK'
            ? price
            : price.toFixed(2)
        } ${pchange}`
    }

    const tt = `🚀CRYPTO UPDATE🚀: ${t} 🌊SURF REPORT🌊: surf is ${surfCheck.surfHeight.toLowerCase()} out of the ${surfCheck.surfDirection.toLowerCase()} with ${surfCheck.surfQuality.toLowerCase()} conditions.  🌡️ TEMPERATURES 🌡️ water: ${surfCheck.waterTemperature.toLowerCase()}  air: ${surfCheck.airTemperature.toLowerCase()} - ${surfCheck.currentWeather.toLowerCase()}`
    setTickerText(tt)
  }

  useEffect(() => {
    if (cp && showHud) {
      const { marketData } = cp

      const s = [
        'bitcoin',
        'ethereum',
        'shiba-inu',
        'matic-network',
        'pepe',
        'dogecoin',
        'pepefork',
      ]
      const t = []

      for (let i = 0; i < s.length; i++) {
        const { price, name, symbol, priceChange1d } = marketData.find(
          d => d.id === s[i]
        )
        const pchange = priceChange1d
        t.push({ id: s[i], price, name, symbol, pchange })
      }

      setPrices(t)
      // bitcoin , ethereum , shib , matic , pepe , elmo , lmwr

      // parse to news
      const n = parseToNews(cp)
    }
  }, [cp])

  useEffect(() => {
    function handleKeyPress(event) {
      if (event.keyCode === 32) {
        // 32 is the key code for the spacebar
        toggleHud()
      }

      if (event.keyCode === 84) {
        setShowHud(false)
        setShowTicker(!showTicker)
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    // Cleanup the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [showHud])

  const toggleHud = () => {
    setShowTicker(false)
    setShowHud(!showHud)
  }

  const changeChannel = () => {
    setChannel('static')
    setTimeout(() => {
      if (channel === maxChannels) {
        setChannel(1)
        setRotation(0)
        return
      }
      const next = channel + 1
      setChannel(next)
    }, 550)
  }

  return (
    <>
      {showHud && (
        <div className='h-100 w-100 blank-screen'>
          <div className='d-flex flex-column'>
            {prices && (
              <div className='d-flex flex-column'>
                {prices.map(p => (
                  <h3
                    key={p.symbol}
                    className='d-flex text-center text-white bg-black px-3 py-1 mono'
                  >
                    <span>{p.symbol}</span>{' '}
                    <span className='ml-3'>
                      ${' '}
                      {p.symbol === 'PEPE' ||
                      p.symbol === 'SHIB' ||
                      p.symbol === 'PORK'
                        ? p.price
                        : p.price.toFixed(2)}
                    </span>
                    <span
                      className={`ml-3 ${
                        p.pchange > 0 ? 'text-primary' : 'text-danger'
                      }`}
                    >
                      {p.pchange}
                    </span>
                  </h3>
                ))}
              </div>
            )}
            <h1 className=' text-white bg-black px-3 py-1 mono mt-4 bb'>
              <Clock />
            </h1>
          </div>
        </div>
      )}
      {showTicker && tickerText && (
        <div className='h-100 w-100 blank-screen ticker'>
          <div className='d-flex flex-column'>
            <NewsTicker tickerText={tickerText} />
          </div>
        </div>
      )}
      <div className='h-100 w-100 bg-black d-flex align-items-center justify-content-center position-relative'>
        {/* <div
        className='w-100 h-100 tv-knob'
        onClick={changeChannel}
        style={{ transform: `rotate(${rotation}deg)` }}
      ></div> */}
        <div
          ref={clickTargetRef}
          className={`w-100 h-100 ${showMonitor ? 'tv-shit' : 'tv-shit-no-bg'}`}
          onClick={changeChannel}
        ></div>
        <div
          className={`w-100 h-100 tv-channel ${numberParser(channel)}-lg ${
            showMonitor ? '' : 'no-monitor'
          }`}
        ></div>
      </div>
    </>
  )
}

export default LargeTv

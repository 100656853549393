const Logo = ({ color = '#FFFFFF' }) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='100%'
      viewBox='0 0 1451 1122'
      enable-background='new 0 0 1451 1122'
    >
      <path
        fill='#FFFFFF'
        opacity='0'
        stroke='none'
        d='
M843.000000,1123.000000 
	C562.000000,1123.000000 281.500000,1123.000000 1.000000,1123.000000 
	C1.000000,749.000000 1.000000,375.000000 1.000000,1.000000 
	C484.666656,1.000000 968.333313,1.000000 1452.000000,1.000000 
	C1452.000000,375.000000 1452.000000,749.000000 1452.000000,1123.000000 
	C1249.166626,1123.000000 1046.333374,1123.000000 843.000000,1123.000000 
M434.999878,852.524780 
	C434.666595,842.121887 434.380707,831.717041 433.969574,821.317261 
	C433.854401,818.404236 432.992401,815.488708 433.123322,812.603821 
	C433.599792,802.107849 434.489899,791.630249 434.945526,781.133667 
	C435.198669,775.301819 435.093079,769.436523 434.805756,763.603210 
	C434.362976,754.614624 433.533936,745.645020 433.089081,736.656494 
	C432.999420,734.845276 433.699554,732.911682 434.369812,731.162598 
	C436.028564,726.833923 438.020905,723.214172 436.712250,717.707520 
	C434.802307,709.670654 435.232483,701.033325 435.063293,692.643982 
	C434.964569,687.749329 435.630219,682.838257 435.973785,677.936096 
	C436.189117,674.863281 436.223633,671.756531 436.727722,668.729675 
	C437.862793,661.914185 440.038269,655.457275 438.344482,648.121643 
	C436.779236,641.342529 437.334259,634.065430 437.038177,627.004150 
	C436.756165,620.278259 436.437683,613.547668 436.453247,606.819824 
	C436.457489,604.989929 437.090210,602.106628 438.318390,601.501892 
	C447.891388,596.788391 447.647461,587.220886 449.978119,578.948914 
	C452.253998,570.871277 447.178528,564.041077 444.828827,557.223389 
	C440.835999,545.638062 434.295105,534.889648 428.424469,524.005554 
	C423.555328,514.978088 418.055786,506.292572 412.950409,497.389648 
	C411.329651,494.563354 410.078430,491.525085 408.658081,488.583832 
	C408.794342,488.124725 408.930573,487.665588 409.066803,487.206482 
	C430.999023,494.361481 451.765747,488.435364 469.598450,477.858154 
	C487.132751,467.457916 500.036774,450.846588 503.194153,427.922638 
	C507.221619,433.791718 510.466888,438.757385 513.957703,443.544037 
	C517.753723,448.749176 521.126953,454.457886 529.083313,454.226990 
	C530.652649,454.181458 532.428833,456.930450 533.872131,458.596466 
	C534.662354,459.508636 534.692627,461.105621 535.516479,461.957947 
	C540.315613,466.923187 544.866272,472.251801 550.280884,476.452209 
	C553.472656,478.928162 558.112244,480.707092 562.088867,480.684906 
	C566.372314,480.660980 570.213928,479.526978 572.751587,484.139709 
	C572.999695,484.590637 573.900146,485.030212 574.402039,484.940369 
	C579.565491,484.016113 584.971863,483.535980 589.226074,480.124084 
	C591.391174,478.387695 593.051575,475.967834 594.725098,473.698364 
	C595.434387,472.736450 596.014221,471.334961 595.926941,470.188507 
	C595.512268,464.737518 597.287781,459.105469 594.435364,453.825531 
	C593.557129,452.199890 593.786804,449.893951 592.740540,448.460541 
	C591.281799,446.462036 589.100830,444.990753 587.135803,443.209381 
	C582.637634,444.739014 577.817749,446.178192 573.161865,448.029694 
	C569.919250,449.319122 568.141541,447.833435 565.910767,445.806366 
	C559.673096,440.138184 552.848267,435.089569 546.857788,429.185028 
	C542.721069,425.107697 538.790894,420.416779 536.190308,415.280121 
	C532.311829,407.619324 532.160522,398.647552 528.153503,390.734589 
	C522.682434,379.930389 518.521484,368.466064 513.716125,357.320404 
	C509.194977,346.834045 504.300415,336.500000 500.075256,325.897675 
	C497.090942,318.408966 494.862823,310.610291 492.546844,302.875244 
	C492.270172,301.951324 493.461304,300.492371 494.151398,299.394104 
	C495.675171,296.969208 498.313965,294.776184 498.725037,292.205109 
	C500.212891,282.899506 501.155334,273.491608 501.899353,264.090302 
	C502.163635,260.750641 503.412720,258.664276 505.870667,256.483856 
	C511.988464,251.056824 516.177612,244.849548 515.178345,235.754578 
	C514.328857,228.022903 514.734436,220.133362 519.899353,213.342606 
	C523.196350,209.007843 525.578613,203.932770 529.123108,199.841064 
	C534.819275,193.265350 540.627441,186.598038 547.350220,181.158173 
	C553.012512,176.576447 560.052307,173.701691 566.466492,170.043518 
	C575.867615,164.681839 585.720703,161.153809 597.000244,162.197861 
	C597.000244,168.919754 596.953491,175.397049 597.030823,181.872849 
	C597.052002,183.640961 596.923401,185.723557 597.769104,187.108963 
	C600.065002,190.869736 598.973572,193.817093 596.754944,197.092010 
	C594.870239,199.874130 592.917053,202.654068 595.846130,206.512039 
	C597.005249,208.038712 595.824219,211.458862 595.454163,213.979492 
	C595.273682,215.208618 594.297852,216.308533 594.044128,217.541428 
	C592.789978,223.636948 594.852966,230.259018 591.089661,235.999985 
	C590.806580,236.431824 591.486450,237.468735 591.668945,238.236191 
	C593.051392,244.049835 590.750610,249.461685 585.943787,253.047623 
	C579.066895,258.177887 572.294128,263.460052 565.672729,268.915100 
	C562.537781,271.497894 559.885193,274.660889 556.963135,277.508850 
	C553.333801,281.046173 549.709229,284.592224 545.974121,288.016205 
	C542.977905,290.762909 541.978088,295.257843 536.863098,296.402191 
	C534.774292,296.869476 533.382141,301.297394 532.060974,304.105896 
	C530.796509,306.794037 530.675171,310.215363 528.939819,312.452026 
	C522.184631,321.158539 524.168274,331.171417 524.105713,340.867889 
	C524.092102,342.974701 524.692383,345.375580 525.798767,347.148804 
	C530.322510,354.399078 539.492188,357.531281 547.779419,355.069305 
	C548.537476,354.844116 549.450562,354.947845 550.253479,355.100281 
	C553.442627,355.705841 555.995911,355.087921 558.020691,352.219940 
	C561.204468,347.710297 564.360352,343.119385 568.066772,339.054077 
	C574.390259,332.118286 581.069824,325.499756 587.810547,318.965363 
	C588.369141,318.423920 591.693665,319.373993 591.766235,319.901550 
	C592.581787,325.831848 593.833008,331.336792 596.601440,336.947296 
	C599.012268,341.833344 598.269775,348.256927 599.048401,353.983734 
	C599.189087,355.018646 599.774475,356.113373 600.453247,356.933289 
	C602.883728,359.869171 607.600586,362.800323 607.471497,365.546143 
	C607.251160,370.234894 610.706177,371.208099 612.457581,373.771332 
	C614.224976,376.357941 616.982605,378.347748 618.415955,381.068176 
	C623.219788,390.186035 630.496155,397.246704 637.988708,403.831299 
	C646.690369,411.478638 656.914795,417.071808 668.643005,418.767822 
	C672.142761,419.273926 672.638000,420.883881 673.086243,423.738892 
	C673.703125,427.668213 674.735962,431.560638 675.929749,435.362366 
	C676.966919,438.665283 679.350891,440.775085 683.014099,439.911499 
	C686.709595,439.040314 689.301208,436.566650 689.535583,432.627808 
	C689.651917,430.672668 688.741638,428.653687 688.279419,426.667419 
	C687.283875,422.389191 687.801941,418.969513 692.811096,417.586609 
	C695.554565,416.829224 698.190308,415.672058 700.858154,414.652283 
	C707.449707,412.132751 711.685791,406.747955 715.785645,401.528320 
	C720.886292,395.034729 726.791138,388.555237 729.638000,381.042480 
	C734.938049,367.055908 738.815125,352.397491 738.926086,337.200226 
	C738.993408,327.966400 738.364441,318.725830 737.955811,309.492401 
	C737.909607,308.448486 737.569458,307.027100 736.839844,306.473358 
	C734.088013,304.384827 734.479980,301.444397 733.765137,298.606018 
	C732.999146,295.564178 730.909241,292.188202 728.370361,290.533722 
	C727.042480,289.668396 722.886719,291.562195 721.130554,293.326904 
	C718.838440,295.630219 717.704956,299.067932 715.987549,301.966949 
	C714.218018,304.953979 712.503662,307.996002 710.455566,310.786713 
	C709.346252,312.298218 706.503296,313.188232 706.310059,314.612122 
	C705.305542,322.012756 703.137817,328.779083 698.195435,334.525665 
	C697.597351,335.221130 696.900208,336.401642 697.099487,337.122101 
	C698.641846,342.698517 695.065308,346.782349 693.285950,351.421753 
	C692.214294,354.216003 691.927002,357.311279 690.853271,360.104492 
	C690.277100,361.603333 688.957825,362.966003 687.665344,364.006958 
	C685.406189,365.826447 682.470337,366.963928 680.605408,369.080872 
	C677.037109,373.131256 673.168518,372.378204 669.168396,370.583313 
	C659.875854,366.413635 652.980774,358.820892 648.379333,350.562561 
	C643.287781,341.424530 640.899414,330.657349 638.140625,320.383057 
	C636.102600,312.792877 634.654663,304.890564 634.264465,297.061493 
	C633.965637,291.065552 634.792236,284.621185 636.856873,279.016693 
	C638.703796,274.003326 644.351501,271.980408 649.623169,272.177612 
	C653.495239,272.322479 655.705017,270.429138 657.436829,267.944824 
	C661.788025,261.703156 667.581665,257.679901 674.471924,254.679550 
	C677.206177,253.488922 679.238342,250.736206 681.677307,248.804672 
	C684.617004,246.476608 687.513672,242.565231 690.666321,242.313629 
	C701.346252,241.461365 707.746948,233.285736 716.283691,228.897385 
	C717.487549,228.278549 717.972839,226.151062 719.175110,225.656921 
	C730.864258,220.852463 742.097534,215.478943 746.698914,202.464981 
	C747.644531,199.790436 747.190125,196.571930 747.148865,193.607407 
	C747.057922,187.086609 742.054871,183.501175 738.032715,179.599945 
	C735.428040,177.073517 731.901123,175.402939 728.601807,173.710938 
	C724.174377,171.440384 720.463257,173.072372 716.447876,175.685501 
	C711.917480,178.633820 706.496033,180.174561 701.758850,182.852341 
	C694.409912,187.006424 687.354065,191.675232 680.095581,195.994186 
	C673.374817,199.993195 666.523926,203.773056 659.781189,207.735977 
	C654.013306,211.125946 648.317566,214.638626 641.607117,218.690399 
	C642.301086,215.609131 642.741577,214.072601 642.984680,212.505417 
	C644.470703,202.923599 646.248169,193.475845 645.253052,183.564331 
	C644.295471,174.026810 645.521973,164.281326 644.751221,154.710770 
	C643.856201,143.598633 638.647766,134.276611 630.825500,126.195190 
	C622.823242,117.927879 612.324402,117.034058 602.041504,114.894791 
	C595.853516,113.607437 590.085083,114.587486 584.184875,115.994881 
	C579.670349,117.071762 574.885254,117.357712 570.603882,118.996796 
	C561.991272,122.294098 553.876770,127.039276 545.112305,129.750488 
	C536.929749,132.281708 529.878174,136.759430 523.442871,141.607132 
	C513.516785,149.084503 504.860321,158.257202 495.741943,166.792679 
	C491.066803,171.168915 485.990784,175.285080 482.116302,180.306122 
	C477.203003,186.673447 473.094543,193.707642 469.076904,200.705688 
	C465.910248,206.221542 463.138092,212.007401 460.715393,217.889450 
	C459.406219,221.068039 459.197052,224.699692 458.491669,228.126953 
	C455.852905,223.852325 454.329529,219.666504 452.796570,215.484207 
	C448.188599,202.912811 443.600769,190.333954 438.960114,177.774628 
	C434.001099,164.353745 429.118042,150.902756 423.971649,137.553650 
	C417.092194,119.709335 410.437378,101.756203 402.809418,84.231407 
	C398.575012,74.503052 393.804016,63.954365 379.730957,65.992188 
	C379.568451,66.015724 379.398224,65.981659 379.231659,65.982323 
	C371.602203,66.012497 363.998657,63.805176 357.252502,70.752190 
	C351.070618,77.118172 347.567566,86.225494 350.988800,94.449936 
	C354.612213,103.160324 358.315247,111.837799 362.022583,120.512932 
	C362.930817,122.638161 364.084290,124.660149 364.960846,126.796944 
	C366.392853,130.287888 367.636383,133.855957 369.056488,137.352051 
	C371.332794,142.955978 373.668793,148.535950 376.018250,154.109741 
	C377.372223,157.321915 377.999847,161.429993 380.379059,163.487106 
	C384.308929,166.884888 388.351624,169.715942 388.879456,175.376038 
	C388.923828,175.851868 389.114655,176.337173 389.332764,176.770050 
	C391.408112,180.888657 393.560333,184.970093 395.542816,189.132553 
	C395.904694,189.892441 396.000763,191.364807 395.536438,191.838196 
	C392.282471,195.155548 394.879852,196.327240 397.297394,198.152756 
	C398.639160,199.165939 399.964294,201.370972 399.849945,202.932861 
	C399.434509,208.606125 403.817627,212.346512 405.118988,217.345535 
	C406.088257,221.069016 408.960693,224.293579 410.962738,227.753540 
	C411.671783,228.978958 412.030975,230.586578 413.043518,231.419983 
	C415.295990,233.273956 414.775116,234.980240 414.126068,237.454132 
	C413.525269,239.744278 414.286804,242.481110 414.826080,244.938324 
	C415.050659,245.961548 416.417206,246.678558 416.984680,247.691528 
	C419.769745,252.663132 422.050476,257.972534 425.283600,262.624512 
	C429.827087,269.161926 429.490875,272.582428 423.004211,274.487183 
	C418.570862,275.789001 414.062256,276.835114 409.585388,277.988007 
	C407.755310,278.459259 405.575287,278.430115 404.147247,279.454742 
	C398.184479,283.733063 392.786530,288.888641 386.538300,292.655670 
	C379.221039,297.067230 374.743439,303.400574 370.802643,310.595001 
	C367.816132,316.047363 363.317291,320.809570 361.075195,326.502625 
	C356.455963,338.231567 350.237671,349.531311 349.612122,362.648041 
	C347.990601,360.221588 346.053589,358.169586 345.086212,355.732605 
	C340.259521,343.573883 335.564392,331.359192 331.057190,319.078278 
	C325.451050,303.802979 320.042969,288.454529 314.625031,273.110962 
	C313.564117,270.106506 313.106293,266.876434 311.921936,263.930695 
	C309.099670,256.910797 305.791962,250.082489 303.071381,243.026031 
	C296.824097,226.822098 291.398132,210.270432 284.434662,194.387848 
	C281.308319,187.257126 275.023773,181.569077 270.676880,174.897614 
	C267.029419,169.299591 261.329132,166.251877 256.683563,161.926376 
	C253.633881,159.086792 250.538666,156.983124 246.601028,156.026581 
	C242.721756,155.084213 238.823563,154.211548 234.912079,153.414200 
	C233.471680,153.120590 231.937973,152.867798 230.500580,153.041443 
	C217.657181,154.593094 204.884811,156.604004 193.737183,163.788727 
	C187.327362,167.919907 180.794388,172.052643 175.133316,177.100342 
	C169.137451,182.446548 163.732895,188.582367 158.757507,194.913528 
	C152.976868,202.269379 147.903015,210.184738 142.609528,217.916870 
	C140.991776,220.279892 139.529541,222.772522 138.230591,225.325119 
	C136.374054,228.973419 134.692429,232.713242 133.020920,236.451889 
	C132.379074,237.887512 132.183044,239.550293 131.407654,240.892166 
	C130.098358,243.157990 127.735313,245.069305 127.161850,247.453552 
	C125.929672,252.576569 124.516113,257.464905 122.106972,262.244110 
	C119.126717,268.156311 117.310829,274.642883 114.682381,280.748077 
	C111.127304,289.005585 108.977493,297.660248 107.038704,306.369812 
	C104.975502,315.638275 103.195625,324.970123 101.322021,334.280334 
	C99.966339,341.016968 98.634918,347.758759 97.343239,354.507904 
	C96.825783,357.211670 95.923294,359.973511 96.088791,362.653809 
	C96.878174,375.437897 93.059418,388.743011 100.027550,400.850006 
	C102.610397,405.337646 104.740463,410.501068 108.446220,413.833832 
	C114.620872,419.386963 122.165207,421.052551 130.126282,415.979736 
	C128.092941,412.511139 129.438370,410.219360 132.524109,407.653503 
	C134.677094,405.863251 136.701782,402.496582 136.689789,399.843536 
	C136.669556,395.371765 134.458237,390.943817 134.148560,386.430206 
	C133.818726,381.622681 135.835403,376.427521 134.688217,371.949646 
	C132.251404,362.437988 137.320892,354.658386 139.065674,346.157135 
	C140.186646,340.695374 143.031952,335.529144 143.722687,330.056091 
	C144.263092,325.774109 145.349152,322.982697 149.288239,321.123932 
	C150.251205,320.669495 150.986450,318.565918 150.915436,317.270508 
	C150.315536,306.325165 155.410004,296.900238 159.127380,287.221954 
	C162.438232,278.602112 166.950516,270.440643 171.003647,262.111450 
	C171.841064,260.390564 173.154846,258.899231 173.971558,257.170929 
	C178.443237,247.707962 183.933670,238.887878 191.706543,231.839096 
	C197.900421,226.222244 204.657974,221.224945 211.191544,215.985321 
	C216.825623,211.467026 226.369293,209.693344 230.898880,213.138702 
	C234.170364,215.627121 236.782623,219.175552 239.140396,222.626022 
	C242.582092,227.662750 245.742783,232.922287 248.631607,238.297333 
	C251.529205,243.688705 253.967102,249.328583 256.567780,254.877686 
	C258.735291,259.502563 260.966553,264.103180 262.948792,268.807587 
	C264.870148,273.367493 266.285339,278.147736 268.322479,282.650360 
	C272.056000,290.902344 276.244812,298.949524 279.925293,307.223755 
	C281.977600,311.837646 282.708771,317.135162 285.224243,321.427856 
	C291.181061,331.593262 291.461090,331.425507 292.035248,340.818542 
	C292.062378,341.262665 292.435608,341.676697 292.597870,342.122894 
	C294.723907,347.968750 296.714569,353.867493 298.987488,359.655457 
	C303.307434,370.656219 307.779419,381.597382 312.207520,392.555511 
	C313.156403,394.903717 314.092224,397.265106 315.200928,399.539062 
	C317.151123,403.538788 319.230072,407.475739 321.811249,412.527466 
	C316.454926,410.967957 312.245117,409.624329 307.973358,408.520752 
	C295.551758,405.311768 283.200012,401.450500 270.097839,403.022644 
	C264.003845,403.753876 257.701752,403.971405 251.914886,405.773438 
	C241.648453,408.970459 231.323685,412.435974 221.730164,417.209656 
	C212.169724,421.966827 203.108368,427.971283 194.511673,434.349976 
	C186.439392,440.339508 181.897141,449.861176 173.770020,456.263519 
	C168.527405,460.393524 165.273438,467.192444 161.533981,473.054138 
	C154.984894,483.320038 148.510498,493.649811 142.486542,504.227203 
	C139.742172,509.046021 138.284714,514.581421 135.984161,519.674622 
	C134.203323,523.617188 130.730408,527.226013 130.257507,531.247559 
	C128.876694,542.990234 125.016068,553.878235 121.172989,564.895996 
	C119.635307,569.304443 119.533058,574.253113 119.058250,578.984070 
	C118.260887,586.928955 117.306801,594.889404 117.160599,602.856934 
	C117.069374,607.827820 119.147736,612.876770 118.824402,617.796875 
	C118.233879,626.782654 120.354141,635.440247 122.752388,643.622986 
	C125.340096,652.452148 130.692780,660.539246 135.331802,668.669006 
	C137.525818,672.514038 140.541382,676.049133 143.771576,679.104187 
	C147.947922,683.054138 152.285172,687.225403 157.325241,689.775269 
	C164.221878,693.264343 171.926636,695.120789 179.107819,698.103577 
	C181.349121,699.034546 184.382278,701.113586 184.714233,703.064880 
	C186.258484,712.142334 187.269058,721.339111 187.916489,730.532288 
	C188.517456,739.066040 188.304764,747.654114 188.673523,756.208618 
	C188.860870,760.555054 189.979279,764.890198 189.882660,769.212646 
	C189.767410,774.369629 188.219223,779.518982 188.319443,784.651550 
	C188.565781,797.265076 189.731155,809.862549 189.906998,822.475037 
	C190.082611,835.072693 189.290985,847.681091 189.311157,860.284180 
	C189.336807,876.313721 189.817261,892.341980 189.978119,908.372131 
	C190.091751,919.695618 189.870758,931.023010 190.038406,942.345215 
	C190.190231,952.598816 190.681168,962.847107 190.973969,973.099182 
	C191.001083,974.048828 190.520981,975.012634 190.546753,975.962585 
	C190.610596,978.316162 190.909866,980.663513 190.966019,983.016968 
	C191.019226,985.247620 190.927139,987.484924 190.811722,989.714844 
	C190.757950,990.754211 190.317398,991.790710 190.360886,992.815369 
	C190.641418,999.429321 191.175522,1006.037170 191.277740,1012.652405 
	C191.333084,1016.232483 195.263062,1023.576111 197.881454,1023.962219 
	C201.550369,1024.503296 207.673599,1020.534546 207.650360,1016.437683 
	C207.605698,1008.565125 213.141190,1000.268494 206.322159,992.781616 
	C206.131546,992.572388 206.095825,992.165405 206.093567,991.849854 
	C206.056686,986.718140 205.914963,981.583374 206.039307,976.455139 
	C206.278763,966.578979 206.814499,956.708191 206.934418,946.831604 
	C206.988007,942.419922 206.020599,937.996216 206.069397,933.584106 
	C206.154846,925.856262 206.890152,918.134216 206.928513,910.407654 
	C206.958710,904.324219 206.052750,898.235474 206.094757,892.152832 
	C206.157394,883.081055 206.834869,874.014893 206.980820,864.942017 
	C207.142502,854.891418 207.000641,844.835938 206.990479,834.782593 
	C206.982758,827.137268 207.091354,819.489380 206.926392,811.847412 
	C206.809952,806.453186 206.021439,801.064331 206.068893,795.677795 
	C206.144455,787.103271 206.923599,778.532837 206.926727,769.960449 
	C206.929794,761.580322 206.161682,753.202393 206.042542,744.819092 
	C205.876785,733.156555 206.086060,721.489014 205.961426,709.825439 
	C205.904266,704.476501 207.912704,700.858765 212.450500,697.683899 
	C218.035675,693.776367 223.215942,689.098389 227.871887,684.101685 
	C234.022095,677.501465 239.421295,670.207214 245.283875,663.331116 
	C248.878006,659.115540 252.872971,655.240479 256.443726,651.006592 
	C262.545593,643.771606 268.500427,636.410889 274.432465,629.034973 
	C279.399109,622.859375 284.275604,616.610413 289.133636,610.348572 
	C292.465271,606.054260 295.682800,601.671570 298.976349,597.347473 
	C303.989746,590.765259 308.849762,584.055420 314.104950,577.671448 
	C318.462158,572.378357 323.621643,567.732727 327.842224,562.343262 
	C334.183807,554.245483 340.130280,545.828308 346.011871,537.384766 
	C348.649384,533.598328 352.723083,530.766968 353.096771,525.394165 
	C353.567963,518.619873 354.747589,511.894897 355.793182,503.824310 
	C358.183014,507.137695 360.321136,509.646820 361.947815,512.452454 
	C365.298767,518.232117 368.401123,524.156982 371.559021,530.047180 
	C374.140320,534.861816 376.678741,539.699951 379.190460,544.551331 
	C381.811615,549.613953 384.354950,554.716797 386.975830,559.779602 
	C389.043823,563.774353 391.225616,567.710754 393.259796,571.722290 
	C394.112488,573.403870 394.237213,575.783936 395.520416,576.855835 
	C399.290131,580.004761 400.971405,584.244263 402.965912,588.449097 
	C403.945587,590.514465 405.884338,592.102356 407.186005,594.042603 
	C409.394775,597.334961 412.545868,600.493347 413.344727,604.136902 
	C415.456146,613.766907 417.339264,623.587585 417.809143,633.404297 
	C418.532227,648.510437 418.079681,663.677490 417.938110,678.816956 
	C417.905731,682.280518 416.575043,685.768555 416.777588,689.189941 
	C417.157990,695.615356 418.669495,701.986023 418.876892,708.406311 
	C419.094421,715.140076 418.242554,721.902466 418.030182,728.657776 
	C417.873383,733.646301 419.058746,738.945618 417.748383,743.568481 
	C415.592468,751.174438 416.224701,758.734558 416.045197,766.326111 
	C415.848511,774.642639 416.059570,782.968140 415.955261,791.288025 
	C415.931366,793.192322 415.052216,795.092285 415.069275,796.989685 
	C415.125977,803.296387 415.325378,809.605225 415.631836,815.905334 
	C415.993652,823.343262 416.450165,830.778503 417.001587,838.204773 
	C417.566284,845.809509 418.655792,853.392883 418.847260,861.002441 
	C419.024445,868.046448 418.086304,875.113342 418.109711,882.168579 
	C418.119629,885.151917 419.647125,888.100952 419.938507,891.118286 
	C420.677582,898.770447 421.194336,906.447449 421.643005,914.123657 
	C422.487152,928.565125 423.360718,943.007263 423.958344,957.460022 
	C424.490723,970.335083 424.519012,983.231201 425.073120,996.105042 
	C425.293793,1001.232117 426.986908,1006.340881 426.856445,1011.428467 
	C426.590118,1021.813965 425.350403,1032.170776 424.881531,1042.556152 
	C424.555237,1049.783325 425.671753,1050.905640 431.218628,1051.000000 
	C436.644135,1051.092407 440.706573,1047.449829 441.003510,1042.242310 
	C441.078033,1040.934937 441.213226,1039.631226 441.295166,1038.324219 
	C441.902954,1028.631104 443.047882,1018.933105 442.965851,1009.245605 
	C442.869873,997.916199 441.623474,986.599792 441.042999,975.269287 
	C440.841522,971.335938 441.265839,967.374573 441.185974,963.430359 
	C440.834137,946.055176 440.567535,928.675415 439.902771,911.311279 
	C439.694336,905.866455 438.331329,900.466492 437.506836,895.044434 
	C436.664764,889.506409 435.381866,883.988953 435.106354,878.422241 
	C434.695801,870.125854 434.999878,861.794128 434.999878,852.524780 
M1099.363770,264.997650 
	C1084.721436,264.997650 1070.079102,264.997650 1055.354736,264.997650 
	C1045.772095,309.139191 1036.265503,352.930695 1026.758911,396.722198 
	C1026.483032,396.743439 1026.207275,396.764648 1025.931519,396.785889 
	C1016.634033,352.926544 1007.336609,309.067200 998.063232,265.321655 
	C981.342834,265.321655 964.919495,265.321655 948.483398,265.321655 
	C939.634155,309.255981 930.846863,352.882751 922.059631,396.509521 
	C921.820007,396.469727 921.580383,396.429901 921.340759,396.390106 
	C911.804016,352.613281 902.267273,308.836426 892.765625,265.220642 
	C875.111572,265.220642 858.014526,265.220642 840.410278,265.220642 
	C857.886536,327.375732 875.267212,389.191162 892.591187,450.804626 
	C909.801514,450.804626 926.426941,450.804626 943.358521,450.804626 
	C953.031799,406.503632 962.678345,362.325378 972.324829,318.147156 
	C972.662537,318.131836 973.000244,318.116516 973.337952,318.101227 
	C982.893921,362.354065 992.449890,406.606903 1001.977783,450.729736 
	C1018.966675,450.729736 1035.549072,450.729736 1052.266602,450.729736 
	C1070.250854,388.778564 1088.164062,327.072784 1106.184326,264.997650 
	C1103.957031,264.997650 1102.137085,264.997650 1099.363770,264.997650 
M1278.597900,203.978760 
	C1275.045166,206.923019 1271.089966,209.502197 1268.020874,212.885254 
	C1260.984497,220.641571 1258.799316,230.437881 1258.008423,240.516220 
	C1257.323730,249.242966 1257.141602,258.009125 1256.719360,267.145081 
	C1247.946777,267.145081 1239.347412,267.145081 1230.909790,267.145081 
	C1230.909790,278.619934 1230.909790,289.692657 1230.909790,301.197845 
	C1239.951050,301.197845 1248.698608,301.197845 1257.896606,301.197845 
	C1257.896606,351.348480 1257.896606,401.060730 1257.896606,450.724426 
	C1274.273682,450.724426 1290.181030,450.724426 1306.516602,450.724426 
	C1306.516602,400.681213 1306.516602,350.946686 1306.516602,300.908234 
	C1317.229492,300.908234 1327.512817,300.908234 1337.963623,300.908234 
	C1337.963623,289.533142 1337.963623,278.466461 1337.963623,266.919495 
	C1327.369141,266.919495 1316.967896,266.919495 1306.305664,266.919495 
	C1306.305664,261.146301 1306.001099,255.826065 1306.374756,250.553894 
	C1306.981567,241.994125 1312.108765,237.279510 1320.676270,237.042023 
	C1325.965942,236.895401 1331.262817,237.014847 1336.798096,237.014847 
	C1336.798096,224.457581 1336.798096,211.364456 1336.798096,198.132996 
	C1334.361694,197.872498 1332.080444,197.515778 1329.787109,197.401413 
	C1312.590088,196.543747 1295.477173,196.428802 1278.597900,203.978760 
M1188.889038,356.500031 
	C1188.889038,338.082703 1188.889038,319.665405 1188.889038,300.876099 
	C1199.278198,300.876099 1209.029541,300.876099 1218.774414,300.876099 
	C1218.774414,289.088959 1218.774414,277.683075 1218.774414,265.920929 
	C1208.761108,265.920929 1199.001953,265.920929 1188.824951,265.920929 
	C1188.824951,248.497543 1188.824951,231.428513 1188.824951,214.319046 
	C1172.506470,214.319046 1156.617554,214.319046 1140.462646,214.319046 
	C1140.462646,231.599823 1140.462646,248.561417 1140.462646,265.969482 
	C1131.788940,265.969482 1123.370850,265.969482 1114.809570,265.969482 
	C1114.809570,277.824219 1114.809570,289.227722 1114.809570,301.108215 
	C1123.406494,301.108215 1131.812500,301.108215 1140.606812,301.108215 
	C1140.606812,308.547638 1140.606812,315.516174 1140.606812,322.484711 
	C1140.607056,353.647797 1140.522827,384.811188 1140.636353,415.973877 
	C1140.715942,437.842987 1149.157349,447.911316 1170.548706,452.238861 
	C1186.582642,455.482574 1202.648438,453.434814 1218.803467,452.927155 
	C1218.803467,440.822876 1218.803467,429.083160 1218.803467,416.918213 
	C1212.285034,416.918213 1205.972534,417.218811 1199.700684,416.842682 
	C1191.728638,416.364594 1189.577759,413.924835 1189.034668,405.991333 
	C1188.921143,404.330353 1188.896240,402.660736 1188.895020,400.995056 
	C1188.884644,386.496735 1188.889160,371.998383 1188.889038,356.500031 
M784.410278,445.004730 
	C790.109192,440.481659 790.892334,433.370728 790.390503,427.542419 
	C790.023071,423.274658 785.412537,417.882446 781.273499,415.760315 
	C774.144836,412.105316 766.073792,411.759399 758.231201,415.736115 
	C750.802734,419.502899 749.652710,437.073273 756.610901,443.380188 
	C759.143188,445.675446 761.342468,448.357941 763.553345,450.981995 
	C765.549133,453.350739 774.762634,456.558044 776.289368,454.961792 
	C779.077515,452.046692 781.490540,448.772827 784.410278,445.004730 
z'
      />
      <path
        fill={color}
        opacity='1.000000'
        stroke='none'
        d='
M434.999878,853.001099 
	C434.999878,861.794128 434.695801,870.125854 435.106354,878.422241 
	C435.381866,883.988953 436.664764,889.506409 437.506836,895.044434 
	C438.331329,900.466492 439.694336,905.866455 439.902771,911.311279 
	C440.567535,928.675415 440.834137,946.055176 441.185974,963.430359 
	C441.265839,967.374573 440.841522,971.335938 441.042999,975.269287 
	C441.623474,986.599792 442.869873,997.916199 442.965851,1009.245605 
	C443.047882,1018.933105 441.902954,1028.631104 441.295166,1038.324219 
	C441.213226,1039.631226 441.078033,1040.934937 441.003510,1042.242310 
	C440.706573,1047.449829 436.644135,1051.092407 431.218628,1051.000000 
	C425.671753,1050.905640 424.555237,1049.783325 424.881531,1042.556152 
	C425.350403,1032.170776 426.590118,1021.813965 426.856445,1011.428467 
	C426.986908,1006.340881 425.293793,1001.232117 425.073120,996.105042 
	C424.519012,983.231201 424.490723,970.335083 423.958344,957.460022 
	C423.360718,943.007263 422.487152,928.565125 421.643005,914.123657 
	C421.194336,906.447449 420.677582,898.770447 419.938507,891.118286 
	C419.647125,888.100952 418.119629,885.151917 418.109711,882.168579 
	C418.086304,875.113342 419.024445,868.046448 418.847260,861.002441 
	C418.655792,853.392883 417.566284,845.809509 417.001587,838.204773 
	C416.450165,830.778503 415.993652,823.343262 415.631836,815.905334 
	C415.325378,809.605225 415.125977,803.296387 415.069275,796.989685 
	C415.052216,795.092285 415.931366,793.192322 415.955261,791.288025 
	C416.059570,782.968140 415.848511,774.642639 416.045197,766.326111 
	C416.224701,758.734558 415.592468,751.174438 417.748383,743.568481 
	C419.058746,738.945618 417.873383,733.646301 418.030182,728.657776 
	C418.242554,721.902466 419.094421,715.140076 418.876892,708.406311 
	C418.669495,701.986023 417.157990,695.615356 416.777588,689.189941 
	C416.575043,685.768555 417.905731,682.280518 417.938110,678.816956 
	C418.079681,663.677490 418.532227,648.510437 417.809143,633.404297 
	C417.339264,623.587585 415.456146,613.766907 413.344727,604.136902 
	C412.545868,600.493347 409.394775,597.334961 407.186005,594.042603 
	C405.884338,592.102356 403.945587,590.514465 402.965912,588.449097 
	C400.971405,584.244263 399.290131,580.004761 395.520416,576.855835 
	C394.237213,575.783936 394.112488,573.403870 393.259796,571.722290 
	C391.225616,567.710754 389.043823,563.774353 386.975830,559.779602 
	C384.354950,554.716797 381.811615,549.613953 379.190460,544.551331 
	C376.678741,539.699951 374.140320,534.861816 371.559021,530.047180 
	C368.401123,524.156982 365.298767,518.232117 361.947815,512.452454 
	C360.321136,509.646820 358.183014,507.137695 355.793182,503.824310 
	C354.747589,511.894897 353.567963,518.619873 353.096771,525.394165 
	C352.723083,530.766968 348.649384,533.598328 346.011871,537.384766 
	C340.130280,545.828308 334.183807,554.245483 327.842224,562.343262 
	C323.621643,567.732727 318.462158,572.378357 314.104950,577.671448 
	C308.849762,584.055420 303.989746,590.765259 298.976349,597.347473 
	C295.682800,601.671570 292.465271,606.054260 289.133636,610.348572 
	C284.275604,616.610413 279.399109,622.859375 274.432465,629.034973 
	C268.500427,636.410889 262.545593,643.771606 256.443726,651.006592 
	C252.872971,655.240479 248.878006,659.115540 245.283875,663.331116 
	C239.421295,670.207214 234.022095,677.501465 227.871887,684.101685 
	C223.215942,689.098389 218.035675,693.776367 212.450500,697.683899 
	C207.912704,700.858765 205.904266,704.476501 205.961426,709.825439 
	C206.086060,721.489014 205.876785,733.156555 206.042542,744.819092 
	C206.161682,753.202393 206.929794,761.580322 206.926727,769.960449 
	C206.923599,778.532837 206.144455,787.103271 206.068893,795.677795 
	C206.021439,801.064331 206.809952,806.453186 206.926392,811.847412 
	C207.091354,819.489380 206.982758,827.137268 206.990479,834.782593 
	C207.000641,844.835938 207.142502,854.891418 206.980820,864.942017 
	C206.834869,874.014893 206.157394,883.081055 206.094757,892.152832 
	C206.052750,898.235474 206.958710,904.324219 206.928513,910.407654 
	C206.890152,918.134216 206.154846,925.856262 206.069397,933.584106 
	C206.020599,937.996216 206.988007,942.419922 206.934418,946.831604 
	C206.814499,956.708191 206.278763,966.578979 206.039307,976.455139 
	C205.914963,981.583374 206.056686,986.718140 206.093567,991.849854 
	C206.095825,992.165405 206.131546,992.572388 206.322159,992.781616 
	C213.141190,1000.268494 207.605698,1008.565125 207.650360,1016.437683 
	C207.673599,1020.534546 201.550369,1024.503296 197.881454,1023.962219 
	C195.263062,1023.576111 191.333084,1016.232483 191.277740,1012.652405 
	C191.175522,1006.037170 190.641418,999.429321 190.360886,992.815369 
	C190.317398,991.790710 190.757950,990.754211 190.811722,989.714844 
	C190.927139,987.484924 191.019226,985.247620 190.966019,983.016968 
	C190.909866,980.663513 190.610596,978.316162 190.546753,975.962585 
	C190.520981,975.012634 191.001083,974.048828 190.973969,973.099182 
	C190.681168,962.847107 190.190231,952.598816 190.038406,942.345215 
	C189.870758,931.023010 190.091751,919.695618 189.978119,908.372131 
	C189.817261,892.341980 189.336807,876.313721 189.311157,860.284180 
	C189.290985,847.681091 190.082611,835.072693 189.906998,822.475037 
	C189.731155,809.862549 188.565781,797.265076 188.319443,784.651550 
	C188.219223,779.518982 189.767410,774.369629 189.882660,769.212646 
	C189.979279,764.890198 188.860870,760.555054 188.673523,756.208618 
	C188.304764,747.654114 188.517456,739.066040 187.916489,730.532288 
	C187.269058,721.339111 186.258484,712.142334 184.714233,703.064880 
	C184.382278,701.113586 181.349121,699.034546 179.107819,698.103577 
	C171.926636,695.120789 164.221878,693.264343 157.325241,689.775269 
	C152.285172,687.225403 147.947922,683.054138 143.771576,679.104187 
	C140.541382,676.049133 137.525818,672.514038 135.331802,668.669006 
	C130.692780,660.539246 125.340096,652.452148 122.752388,643.622986 
	C120.354141,635.440247 118.233879,626.782654 118.824402,617.796875 
	C119.147736,612.876770 117.069374,607.827820 117.160599,602.856934 
	C117.306801,594.889404 118.260887,586.928955 119.058250,578.984070 
	C119.533058,574.253113 119.635307,569.304443 121.172989,564.895996 
	C125.016068,553.878235 128.876694,542.990234 130.257507,531.247559 
	C130.730408,527.226013 134.203323,523.617188 135.984161,519.674622 
	C138.284714,514.581421 139.742172,509.046021 142.486542,504.227203 
	C148.510498,493.649811 154.984894,483.320038 161.533981,473.054138 
	C165.273438,467.192444 168.527405,460.393524 173.770020,456.263519 
	C181.897141,449.861176 186.439392,440.339508 194.511673,434.349976 
	C203.108368,427.971283 212.169724,421.966827 221.730164,417.209656 
	C231.323685,412.435974 241.648453,408.970459 251.914886,405.773438 
	C257.701752,403.971405 264.003845,403.753876 270.097839,403.022644 
	C283.200012,401.450500 295.551758,405.311768 307.973358,408.520752 
	C312.245117,409.624329 316.454926,410.967957 321.811249,412.527466 
	C319.230072,407.475739 317.151123,403.538788 315.200928,399.539062 
	C314.092224,397.265106 313.156403,394.903717 312.207520,392.555511 
	C307.779419,381.597382 303.307434,370.656219 298.987488,359.655457 
	C296.714569,353.867493 294.723907,347.968750 292.597870,342.122894 
	C292.435608,341.676697 292.062378,341.262665 292.035248,340.818542 
	C291.461090,331.425507 291.181061,331.593262 285.224243,321.427856 
	C282.708771,317.135162 281.977600,311.837646 279.925293,307.223755 
	C276.244812,298.949524 272.056000,290.902344 268.322479,282.650360 
	C266.285339,278.147736 264.870148,273.367493 262.948792,268.807587 
	C260.966553,264.103180 258.735291,259.502563 256.567780,254.877686 
	C253.967102,249.328583 251.529205,243.688705 248.631607,238.297333 
	C245.742783,232.922287 242.582092,227.662750 239.140396,222.626022 
	C236.782623,219.175552 234.170364,215.627121 230.898880,213.138702 
	C226.369293,209.693344 216.825623,211.467026 211.191544,215.985321 
	C204.657974,221.224945 197.900421,226.222244 191.706543,231.839096 
	C183.933670,238.887878 178.443237,247.707962 173.971558,257.170929 
	C173.154846,258.899231 171.841064,260.390564 171.003647,262.111450 
	C166.950516,270.440643 162.438232,278.602112 159.127380,287.221954 
	C155.410004,296.900238 150.315536,306.325165 150.915436,317.270508 
	C150.986450,318.565918 150.251205,320.669495 149.288239,321.123932 
	C145.349152,322.982697 144.263092,325.774109 143.722687,330.056091 
	C143.031952,335.529144 140.186646,340.695374 139.065674,346.157135 
	C137.320892,354.658386 132.251404,362.437988 134.688217,371.949646 
	C135.835403,376.427521 133.818726,381.622681 134.148560,386.430206 
	C134.458237,390.943817 136.669556,395.371765 136.689789,399.843536 
	C136.701782,402.496582 134.677094,405.863251 132.524109,407.653503 
	C129.438370,410.219360 128.092941,412.511139 130.126282,415.979736 
	C122.165207,421.052551 114.620872,419.386963 108.446220,413.833832 
	C104.740463,410.501068 102.610397,405.337646 100.027550,400.850006 
	C93.059418,388.743011 96.878174,375.437897 96.088791,362.653809 
	C95.923294,359.973511 96.825783,357.211670 97.343239,354.507904 
	C98.634918,347.758759 99.966339,341.016968 101.322021,334.280334 
	C103.195625,324.970123 104.975502,315.638275 107.038704,306.369812 
	C108.977493,297.660248 111.127304,289.005585 114.682381,280.748077 
	C117.310829,274.642883 119.126717,268.156311 122.106972,262.244110 
	C124.516113,257.464905 125.929672,252.576569 127.161850,247.453552 
	C127.735313,245.069305 130.098358,243.157990 131.407654,240.892166 
	C132.183044,239.550293 132.379074,237.887512 133.020920,236.451889 
	C134.692429,232.713242 136.374054,228.973419 138.230591,225.325119 
	C139.529541,222.772522 140.991776,220.279892 142.609528,217.916870 
	C147.903015,210.184738 152.976868,202.269379 158.757507,194.913528 
	C163.732895,188.582367 169.137451,182.446548 175.133316,177.100342 
	C180.794388,172.052643 187.327362,167.919907 193.737183,163.788727 
	C204.884811,156.604004 217.657181,154.593094 230.500580,153.041443 
	C231.937973,152.867798 233.471680,153.120590 234.912079,153.414200 
	C238.823563,154.211548 242.721756,155.084213 246.601028,156.026581 
	C250.538666,156.983124 253.633881,159.086792 256.683563,161.926376 
	C261.329132,166.251877 267.029419,169.299591 270.676880,174.897614 
	C275.023773,181.569077 281.308319,187.257126 284.434662,194.387848 
	C291.398132,210.270432 296.824097,226.822098 303.071381,243.026031 
	C305.791962,250.082489 309.099670,256.910797 311.921936,263.930695 
	C313.106293,266.876434 313.564117,270.106506 314.625031,273.110962 
	C320.042969,288.454529 325.451050,303.802979 331.057190,319.078278 
	C335.564392,331.359192 340.259521,343.573883 345.086212,355.732605 
	C346.053589,358.169586 347.990601,360.221588 349.612122,362.648041 
	C350.237671,349.531311 356.455963,338.231567 361.075195,326.502625 
	C363.317291,320.809570 367.816132,316.047363 370.802643,310.595001 
	C374.743439,303.400574 379.221039,297.067230 386.538300,292.655670 
	C392.786530,288.888641 398.184479,283.733063 404.147247,279.454742 
	C405.575287,278.430115 407.755310,278.459259 409.585388,277.988007 
	C414.062256,276.835114 418.570862,275.789001 423.004211,274.487183 
	C429.490875,272.582428 429.827087,269.161926 425.283600,262.624512 
	C422.050476,257.972534 419.769745,252.663132 416.984680,247.691528 
	C416.417206,246.678558 415.050659,245.961548 414.826080,244.938324 
	C414.286804,242.481110 413.525269,239.744278 414.126068,237.454132 
	C414.775116,234.980240 415.295990,233.273956 413.043518,231.419983 
	C412.030975,230.586578 411.671783,228.978958 410.962738,227.753540 
	C408.960693,224.293579 406.088257,221.069016 405.118988,217.345535 
	C403.817627,212.346512 399.434509,208.606125 399.849945,202.932861 
	C399.964294,201.370972 398.639160,199.165939 397.297394,198.152756 
	C394.879852,196.327240 392.282471,195.155548 395.536438,191.838196 
	C396.000763,191.364807 395.904694,189.892441 395.542816,189.132553 
	C393.560333,184.970093 391.408112,180.888657 389.332764,176.770050 
	C389.114655,176.337173 388.923828,175.851868 388.879456,175.376038 
	C388.351624,169.715942 384.308929,166.884888 380.379059,163.487106 
	C377.999847,161.429993 377.372223,157.321915 376.018250,154.109741 
	C373.668793,148.535950 371.332794,142.955978 369.056488,137.352051 
	C367.636383,133.855957 366.392853,130.287888 364.960846,126.796944 
	C364.084290,124.660149 362.930817,122.638161 362.022583,120.512932 
	C358.315247,111.837799 354.612213,103.160324 350.988800,94.449936 
	C347.567566,86.225494 351.070618,77.118172 357.252502,70.752190 
	C363.998657,63.805176 371.602203,66.012497 379.231659,65.982323 
	C379.398224,65.981659 379.568451,66.015724 379.730957,65.992188 
	C393.804016,63.954365 398.575012,74.503052 402.809418,84.231407 
	C410.437378,101.756203 417.092194,119.709335 423.971649,137.553650 
	C429.118042,150.902756 434.001099,164.353745 438.960114,177.774628 
	C443.600769,190.333954 448.188599,202.912811 452.796570,215.484207 
	C454.329529,219.666504 455.852905,223.852325 458.491669,228.126953 
	C459.197052,224.699692 459.406219,221.068039 460.715393,217.889450 
	C463.138092,212.007401 465.910248,206.221542 469.076904,200.705688 
	C473.094543,193.707642 477.203003,186.673447 482.116302,180.306122 
	C485.990784,175.285080 491.066803,171.168915 495.741943,166.792679 
	C504.860321,158.257202 513.516785,149.084503 523.442871,141.607132 
	C529.878174,136.759430 536.929749,132.281708 545.112305,129.750488 
	C553.876770,127.039276 561.991272,122.294098 570.603882,118.996796 
	C574.885254,117.357712 579.670349,117.071762 584.184875,115.994881 
	C590.085083,114.587486 595.853516,113.607437 602.041504,114.894791 
	C612.324402,117.034058 622.823242,117.927879 630.825500,126.195190 
	C638.647766,134.276611 643.856201,143.598633 644.751221,154.710770 
	C645.521973,164.281326 644.295471,174.026810 645.253052,183.564331 
	C646.248169,193.475845 644.470703,202.923599 642.984680,212.505417 
	C642.741577,214.072601 642.301086,215.609131 641.607117,218.690399 
	C648.317566,214.638626 654.013306,211.125946 659.781189,207.735977 
	C666.523926,203.773056 673.374817,199.993195 680.095581,195.994186 
	C687.354065,191.675232 694.409912,187.006424 701.758850,182.852341 
	C706.496033,180.174561 711.917480,178.633820 716.447876,175.685501 
	C720.463257,173.072372 724.174377,171.440384 728.601807,173.710938 
	C731.901123,175.402939 735.428040,177.073517 738.032715,179.599945 
	C742.054871,183.501175 747.057922,187.086609 747.148865,193.607407 
	C747.190125,196.571930 747.644531,199.790436 746.698914,202.464981 
	C742.097534,215.478943 730.864258,220.852463 719.175110,225.656921 
	C717.972839,226.151062 717.487549,228.278549 716.283691,228.897385 
	C707.746948,233.285736 701.346252,241.461365 690.666321,242.313629 
	C687.513672,242.565231 684.617004,246.476608 681.677307,248.804672 
	C679.238342,250.736206 677.206177,253.488922 674.471924,254.679550 
	C667.581665,257.679901 661.788025,261.703156 657.436829,267.944824 
	C655.705017,270.429138 653.495239,272.322479 649.623169,272.177612 
	C644.351501,271.980408 638.703796,274.003326 636.856873,279.016693 
	C634.792236,284.621185 633.965637,291.065552 634.264465,297.061493 
	C634.654663,304.890564 636.102600,312.792877 638.140625,320.383057 
	C640.899414,330.657349 643.287781,341.424530 648.379333,350.562561 
	C652.980774,358.820892 659.875854,366.413635 669.168396,370.583313 
	C673.168518,372.378204 677.037109,373.131256 680.605408,369.080872 
	C682.470337,366.963928 685.406189,365.826447 687.665344,364.006958 
	C688.957825,362.966003 690.277100,361.603333 690.853271,360.104492 
	C691.927002,357.311279 692.214294,354.216003 693.285950,351.421753 
	C695.065308,346.782349 698.641846,342.698517 697.099487,337.122101 
	C696.900208,336.401642 697.597351,335.221130 698.195435,334.525665 
	C703.137817,328.779083 705.305542,322.012756 706.310059,314.612122 
	C706.503296,313.188232 709.346252,312.298218 710.455566,310.786713 
	C712.503662,307.996002 714.218018,304.953979 715.987549,301.966949 
	C717.704956,299.067932 718.838440,295.630219 721.130554,293.326904 
	C722.886719,291.562195 727.042480,289.668396 728.370361,290.533722 
	C730.909241,292.188202 732.999146,295.564178 733.765137,298.606018 
	C734.479980,301.444397 734.088013,304.384827 736.839844,306.473358 
	C737.569458,307.027100 737.909607,308.448486 737.955811,309.492401 
	C738.364441,318.725830 738.993408,327.966400 738.926086,337.200226 
	C738.815125,352.397491 734.938049,367.055908 729.638000,381.042480 
	C726.791138,388.555237 720.886292,395.034729 715.785645,401.528320 
	C711.685791,406.747955 707.449707,412.132751 700.858154,414.652283 
	C698.190308,415.672058 695.554565,416.829224 692.811096,417.586609 
	C687.801941,418.969513 687.283875,422.389191 688.279419,426.667419 
	C688.741638,428.653687 689.651917,430.672668 689.535583,432.627808 
	C689.301208,436.566650 686.709595,439.040314 683.014099,439.911499 
	C679.350891,440.775085 676.966919,438.665283 675.929749,435.362366 
	C674.735962,431.560638 673.703125,427.668213 673.086243,423.738892 
	C672.638000,420.883881 672.142761,419.273926 668.643005,418.767822 
	C656.914795,417.071808 646.690369,411.478638 637.988708,403.831299 
	C630.496155,397.246704 623.219788,390.186035 618.415955,381.068176 
	C616.982605,378.347748 614.224976,376.357941 612.457581,373.771332 
	C610.706177,371.208099 607.251160,370.234894 607.471497,365.546143 
	C607.600586,362.800323 602.883728,359.869171 600.453247,356.933289 
	C599.774475,356.113373 599.189087,355.018646 599.048401,353.983734 
	C598.269775,348.256927 599.012268,341.833344 596.601440,336.947296 
	C593.833008,331.336792 592.581787,325.831848 591.766235,319.901550 
	C591.693665,319.373993 588.369141,318.423920 587.810547,318.965363 
	C581.069824,325.499756 574.390259,332.118286 568.066772,339.054077 
	C564.360352,343.119385 561.204468,347.710297 558.020691,352.219940 
	C555.995911,355.087921 553.442627,355.705841 550.253479,355.100281 
	C549.450562,354.947845 548.537476,354.844116 547.779419,355.069305 
	C539.492188,357.531281 530.322510,354.399078 525.798767,347.148804 
	C524.692383,345.375580 524.092102,342.974701 524.105713,340.867889 
	C524.168274,331.171417 522.184631,321.158539 528.939819,312.452026 
	C530.675171,310.215363 530.796509,306.794037 532.060974,304.105896 
	C533.382141,301.297394 534.774292,296.869476 536.863098,296.402191 
	C541.978088,295.257843 542.977905,290.762909 545.974121,288.016205 
	C549.709229,284.592224 553.333801,281.046173 556.963135,277.508850 
	C559.885193,274.660889 562.537781,271.497894 565.672729,268.915100 
	C572.294128,263.460052 579.066895,258.177887 585.943787,253.047623 
	C590.750610,249.461685 593.051392,244.049835 591.668945,238.236191 
	C591.486450,237.468735 590.806580,236.431824 591.089661,235.999985 
	C594.852966,230.259018 592.789978,223.636948 594.044128,217.541428 
	C594.297852,216.308533 595.273682,215.208618 595.454163,213.979492 
	C595.824219,211.458862 597.005249,208.038712 595.846130,206.512039 
	C592.917053,202.654068 594.870239,199.874130 596.754944,197.092010 
	C598.973572,193.817093 600.065002,190.869736 597.769104,187.108963 
	C596.923401,185.723557 597.052002,183.640961 597.030823,181.872849 
	C596.953491,175.397049 597.000244,168.919754 597.000244,162.197861 
	C585.720703,161.153809 575.867615,164.681839 566.466492,170.043518 
	C560.052307,173.701691 553.012512,176.576447 547.350220,181.158173 
	C540.627441,186.598038 534.819275,193.265350 529.123108,199.841064 
	C525.578613,203.932770 523.196350,209.007843 519.899353,213.342606 
	C514.734436,220.133362 514.328857,228.022903 515.178345,235.754578 
	C516.177612,244.849548 511.988464,251.056824 505.870667,256.483856 
	C503.412720,258.664276 502.163635,260.750641 501.899353,264.090302 
	C501.155334,273.491608 500.212891,282.899506 498.725037,292.205109 
	C498.313965,294.776184 495.675171,296.969208 494.151398,299.394104 
	C493.461304,300.492371 492.270172,301.951324 492.546844,302.875244 
	C494.862823,310.610291 497.090942,318.408966 500.075256,325.897675 
	C504.300415,336.500000 509.194977,346.834045 513.716125,357.320404 
	C518.521484,368.466064 522.682434,379.930389 528.153503,390.734589 
	C532.160522,398.647552 532.311829,407.619324 536.190308,415.280121 
	C538.790894,420.416779 542.721069,425.107697 546.857788,429.185028 
	C552.848267,435.089569 559.673096,440.138184 565.910767,445.806366 
	C568.141541,447.833435 569.919250,449.319122 573.161865,448.029694 
	C577.817749,446.178192 582.637634,444.739014 587.135803,443.209381 
	C589.100830,444.990753 591.281799,446.462036 592.740540,448.460541 
	C593.786804,449.893951 593.557129,452.199890 594.435364,453.825531 
	C597.287781,459.105469 595.512268,464.737518 595.926941,470.188507 
	C596.014221,471.334961 595.434387,472.736450 594.725098,473.698364 
	C593.051575,475.967834 591.391174,478.387695 589.226074,480.124084 
	C584.971863,483.535980 579.565491,484.016113 574.402039,484.940369 
	C573.900146,485.030212 572.999695,484.590637 572.751587,484.139709 
	C570.213928,479.526978 566.372314,480.660980 562.088867,480.684906 
	C558.112244,480.707092 553.472656,478.928162 550.280884,476.452209 
	C544.866272,472.251801 540.315613,466.923187 535.516479,461.957947 
	C534.692627,461.105621 534.662354,459.508636 533.872131,458.596466 
	C532.428833,456.930450 530.652649,454.181458 529.083313,454.226990 
	C521.126953,454.457886 517.753723,448.749176 513.957703,443.544037 
	C510.466888,438.757385 507.221619,433.791718 503.194153,427.922638 
	C500.036774,450.846588 487.132751,467.457916 469.598450,477.858154 
	C451.765747,488.435364 430.999023,494.361481 409.066803,487.206482 
	C408.930573,487.665588 408.794342,488.124725 408.658081,488.583832 
	C410.078430,491.525085 411.329651,494.563354 412.950409,497.389648 
	C418.055786,506.292572 423.555328,514.978088 428.424469,524.005554 
	C434.295105,534.889648 440.835999,545.638062 444.828827,557.223389 
	C447.178528,564.041077 452.253998,570.871277 449.978119,578.948914 
	C447.647461,587.220886 447.891388,596.788391 438.318390,601.501892 
	C437.090210,602.106628 436.457489,604.989929 436.453247,606.819824 
	C436.437683,613.547668 436.756165,620.278259 437.038177,627.004150 
	C437.334259,634.065430 436.779236,641.342529 438.344482,648.121643 
	C440.038269,655.457275 437.862793,661.914185 436.727722,668.729675 
	C436.223633,671.756531 436.189117,674.863281 435.973785,677.936096 
	C435.630219,682.838257 434.964569,687.749329 435.063293,692.643982 
	C435.232483,701.033325 434.802307,709.670654 436.712250,717.707520 
	C438.020905,723.214172 436.028564,726.833923 434.369812,731.162598 
	C433.699554,732.911682 432.999420,734.845276 433.089081,736.656494 
	C433.533936,745.645020 434.362976,754.614624 434.805756,763.603210 
	C435.093079,769.436523 435.198669,775.301819 434.945526,781.133667 
	C434.489899,791.630249 433.599792,802.107849 433.123322,812.603821 
	C432.992401,815.488708 433.854401,818.404236 433.969574,821.317261 
	C434.380707,831.717041 434.666595,842.121887 434.999878,853.001099 
M247.002808,587.495300 
	C250.673233,583.616333 254.522537,579.888794 257.968719,575.819885 
	C262.264008,570.748413 266.245270,565.409668 270.322540,560.155579 
	C271.619019,558.484924 272.747467,556.684692 274.024353,554.997925 
	C278.759613,548.742554 283.261230,542.284607 288.350220,536.329712 
	C293.827271,529.920654 300.141815,524.221558 305.536163,517.750244 
	C308.027191,514.761902 309.927307,510.844421 310.815857,507.048767 
	C312.592163,499.460846 316.651855,493.899384 322.905945,489.504181 
	C327.112762,486.547760 330.997467,483.132874 335.648987,479.426941 
	C328.845703,477.032867 326.527710,470.309784 320.300598,466.760986 
	C314.928497,463.699402 309.109894,461.266663 303.797028,458.025116 
	C301.493713,456.619751 299.234833,454.757599 296.710724,454.186920 
	C292.810120,453.304993 288.510193,453.997955 284.713287,452.884918 
	C273.965179,449.734192 263.592987,451.481720 253.080093,453.924316 
	C238.321274,457.353424 228.404709,467.969025 218.117203,477.695770 
	C211.696869,483.766174 206.339630,491.115387 201.271881,498.422394 
	C195.103806,507.315826 188.864243,516.355957 184.287384,526.099182 
	C180.067337,535.082642 177.718475,545.001831 175.080170,554.654968 
	C172.684174,563.421631 170.526978,572.311340 169.153366,581.282837 
	C167.839981,589.861145 167.239761,598.609985 167.144348,607.292908 
	C167.074844,613.615601 167.667801,620.119629 169.194336,626.236145 
	C170.681198,632.193604 173.080078,638.177368 176.372513,643.321350 
	C177.980835,645.834167 181.822861,648.986511 186.056808,646.948486 
	C192.016083,644.080078 199.159927,643.492310 203.768326,638.007446 
	C205.400894,636.064270 206.652924,633.784729 208.379028,631.939819 
	C214.336670,625.572083 220.708038,619.570862 226.394714,612.975464 
	C233.357727,604.899719 239.789566,596.366028 247.002808,587.495300 
M444.028412,319.271088 
	C443.641052,318.854034 443.171783,318.022766 442.878418,318.080811 
	C436.491760,319.344360 429.055298,319.070801 423.976166,322.390594 
	C413.364929,329.326202 404.109070,338.227600 401.928436,351.940704 
	C401.686523,353.462158 400.717346,354.860107 400.144531,356.338226 
	C398.082886,361.658234 394.950287,366.847382 394.244446,372.345551 
	C393.029999,381.806061 392.454102,391.538544 393.284180,401.010132 
	C393.923706,408.307587 396.105804,415.910248 399.469513,422.410828 
	C403.200226,429.620636 408.110626,436.471497 415.908966,440.567261 
	C421.460449,443.482971 427.088135,445.339111 433.067596,443.342072 
	C444.915192,439.385101 453.233948,431.399109 458.090210,419.825684 
	C458.411530,419.059937 459.519196,418.642670 460.213623,418.011139 
	C461.701416,416.658112 464.267914,415.387909 464.427277,413.876587 
	C464.989319,408.546387 469.002228,403.542450 466.107391,397.807098 
	C465.735809,397.070923 466.603302,395.723358 466.864227,394.651093 
	C467.523254,391.942566 469.459778,388.662079 468.567932,386.624908 
	C465.920105,380.576660 468.891510,374.880280 468.797150,369.071167 
	C468.704742,363.383423 468.470673,358.355896 465.130005,353.581604 
	C462.491150,349.810364 460.208496,345.770355 458.011383,341.717560 
	C456.838318,339.553802 456.559448,336.858551 455.217194,334.842468 
	C451.824188,329.745941 448.057800,324.898041 444.028412,319.271088 
M469.888611,259.384979 
	C471.708160,262.517670 474.170959,265.458496 475.177521,268.833649 
	C476.325714,272.683563 477.978790,275.366211 482.000061,276.514893 
	C482.000061,275.237091 481.883484,274.060669 482.019409,272.914154 
	C482.769318,266.587341 484.841827,259.933563 476.848114,256.234436 
	C475.552704,255.634964 474.754303,254.030640 473.605286,253.029526 
	C471.904633,251.547791 469.877106,250.385376 468.395050,248.724411 
	C466.939697,247.093369 465.991852,245.009521 464.821289,243.124390 
	C464.509857,243.318481 464.198456,243.512589 463.887024,243.706680 
	C465.696655,248.754425 467.506256,253.802170 469.888611,259.384979 
z'
      />
      <path
        fill={color}
        opacity='1.000000'
        stroke='none'
        d='
M1099.840454,264.997650 
	C1102.137085,264.997650 1103.957031,264.997650 1106.184326,264.997650 
	C1088.164062,327.072784 1070.250854,388.778564 1052.266602,450.729736 
	C1035.549072,450.729736 1018.966675,450.729736 1001.977783,450.729736 
	C992.449890,406.606903 982.893921,362.354065 973.337952,318.101227 
	C973.000244,318.116516 972.662537,318.131836 972.324829,318.147156 
	C962.678345,362.325378 953.031799,406.503632 943.358521,450.804626 
	C926.426941,450.804626 909.801514,450.804626 892.591187,450.804626 
	C875.267212,389.191162 857.886536,327.375732 840.410278,265.220642 
	C858.014526,265.220642 875.111572,265.220642 892.765625,265.220642 
	C902.267273,308.836426 911.804016,352.613281 921.340759,396.390106 
	C921.580383,396.429901 921.820007,396.469727 922.059631,396.509521 
	C930.846863,352.882751 939.634155,309.255981 948.483398,265.321655 
	C964.919495,265.321655 981.342834,265.321655 998.063232,265.321655 
	C1007.336609,309.067200 1016.634033,352.926544 1025.931519,396.785889 
	C1026.207275,396.764648 1026.483032,396.743439 1026.758911,396.722198 
	C1036.265503,352.930695 1045.772095,309.139191 1055.354736,264.997650 
	C1070.079102,264.997650 1084.721436,264.997650 1099.840454,264.997650 
z'
      />
      <path
        fill={color}
        opacity='1.000000'
        stroke='none'
        d='
M1278.939209,203.821167 
	C1295.477173,196.428802 1312.590088,196.543747 1329.787109,197.401413 
	C1332.080444,197.515778 1334.361694,197.872498 1336.798096,198.132996 
	C1336.798096,211.364456 1336.798096,224.457581 1336.798096,237.014847 
	C1331.262817,237.014847 1325.965942,236.895401 1320.676270,237.042023 
	C1312.108765,237.279510 1306.981567,241.994125 1306.374756,250.553894 
	C1306.001099,255.826065 1306.305664,261.146301 1306.305664,266.919495 
	C1316.967896,266.919495 1327.369141,266.919495 1337.963623,266.919495 
	C1337.963623,278.466461 1337.963623,289.533142 1337.963623,300.908234 
	C1327.512817,300.908234 1317.229492,300.908234 1306.516602,300.908234 
	C1306.516602,350.946686 1306.516602,400.681213 1306.516602,450.724426 
	C1290.181030,450.724426 1274.273682,450.724426 1257.896606,450.724426 
	C1257.896606,401.060730 1257.896606,351.348480 1257.896606,301.197845 
	C1248.698608,301.197845 1239.951050,301.197845 1230.909790,301.197845 
	C1230.909790,289.692657 1230.909790,278.619934 1230.909790,267.145081 
	C1239.347412,267.145081 1247.946777,267.145081 1256.719360,267.145081 
	C1257.141602,258.009125 1257.323730,249.242966 1258.008423,240.516220 
	C1258.799316,230.437881 1260.984497,220.641571 1268.020874,212.885254 
	C1271.089966,209.502197 1275.045166,206.923019 1278.939209,203.821167 
z'
      />
      <path
        fill={color}
        opacity='1.000000'
        stroke='none'
        d='
M1188.889038,357.000061 
	C1188.889160,371.998383 1188.884644,386.496735 1188.895020,400.995056 
	C1188.896240,402.660736 1188.921143,404.330353 1189.034668,405.991333 
	C1189.577759,413.924835 1191.728638,416.364594 1199.700684,416.842682 
	C1205.972534,417.218811 1212.285034,416.918213 1218.803467,416.918213 
	C1218.803467,429.083160 1218.803467,440.822876 1218.803467,452.927155 
	C1202.648438,453.434814 1186.582642,455.482574 1170.548706,452.238861 
	C1149.157349,447.911316 1140.715942,437.842987 1140.636353,415.973877 
	C1140.522827,384.811188 1140.607056,353.647797 1140.606812,322.484711 
	C1140.606812,315.516174 1140.606812,308.547638 1140.606812,301.108215 
	C1131.812500,301.108215 1123.406494,301.108215 1114.809570,301.108215 
	C1114.809570,289.227722 1114.809570,277.824219 1114.809570,265.969482 
	C1123.370850,265.969482 1131.788940,265.969482 1140.462646,265.969482 
	C1140.462646,248.561417 1140.462646,231.599823 1140.462646,214.319046 
	C1156.617554,214.319046 1172.506470,214.319046 1188.824951,214.319046 
	C1188.824951,231.428513 1188.824951,248.497543 1188.824951,265.920929 
	C1199.001953,265.920929 1208.761108,265.920929 1218.774414,265.920929 
	C1218.774414,277.683075 1218.774414,289.088959 1218.774414,300.876099 
	C1209.029541,300.876099 1199.278198,300.876099 1188.889038,300.876099 
	C1188.889038,319.665405 1188.889038,338.082703 1188.889038,357.000061 
z'
      />
      <path
        fill={color}
        opacity='1.000000'
        stroke='none'
        d='
M784.235168,445.326691 
	C781.490540,448.772827 779.077515,452.046692 776.289368,454.961792 
	C774.762634,456.558044 765.549133,453.350739 763.553345,450.981995 
	C761.342468,448.357941 759.143188,445.675446 756.610901,443.380188 
	C749.652710,437.073273 750.802734,419.502899 758.231201,415.736115 
	C766.073792,411.759399 774.144836,412.105316 781.273499,415.760315 
	C785.412537,417.882446 790.023071,423.274658 790.390503,427.542419 
	C790.892334,433.370728 790.109192,440.481659 784.235168,445.326691 
z'
      />
      <path
        fill='#FFFFFF'
        opacity='0'
        stroke='none'
        d='
M246.726929,587.762817 
	C239.789566,596.366028 233.357727,604.899719 226.394714,612.975464 
	C220.708038,619.570862 214.336670,625.572083 208.379028,631.939819 
	C206.652924,633.784729 205.400894,636.064270 203.768326,638.007446 
	C199.159927,643.492310 192.016083,644.080078 186.056808,646.948486 
	C181.822861,648.986511 177.980835,645.834167 176.372513,643.321350 
	C173.080078,638.177368 170.681198,632.193604 169.194336,626.236145 
	C167.667801,620.119629 167.074844,613.615601 167.144348,607.292908 
	C167.239761,598.609985 167.839981,589.861145 169.153366,581.282837 
	C170.526978,572.311340 172.684174,563.421631 175.080170,554.654968 
	C177.718475,545.001831 180.067337,535.082642 184.287384,526.099182 
	C188.864243,516.355957 195.103806,507.315826 201.271881,498.422394 
	C206.339630,491.115387 211.696869,483.766174 218.117203,477.695770 
	C228.404709,467.969025 238.321274,457.353424 253.080093,453.924316 
	C263.592987,451.481720 273.965179,449.734192 284.713287,452.884918 
	C288.510193,453.997955 292.810120,453.304993 296.710724,454.186920 
	C299.234833,454.757599 301.493713,456.619751 303.797028,458.025116 
	C309.109894,461.266663 314.928497,463.699402 320.300598,466.760986 
	C326.527710,470.309784 328.845703,477.032867 335.648987,479.426941 
	C330.997467,483.132874 327.112762,486.547760 322.905945,489.504181 
	C316.651855,493.899384 312.592163,499.460846 310.815857,507.048767 
	C309.927307,510.844421 308.027191,514.761902 305.536163,517.750244 
	C300.141815,524.221558 293.827271,529.920654 288.350220,536.329712 
	C283.261230,542.284607 278.759613,548.742554 274.024353,554.997925 
	C272.747467,556.684692 271.619019,558.484924 270.322540,560.155579 
	C266.245270,565.409668 262.264008,570.748413 257.968719,575.819885 
	C254.522537,579.888794 250.673233,583.616333 246.726929,587.762817 
z'
      />
      <path
        fill='#FFFFFF'
        opacity='0.000000'
        stroke='none'
        d='
M444.234680,319.610840 
	C448.057800,324.898041 451.824188,329.745941 455.217194,334.842468 
	C456.559448,336.858551 456.838318,339.553802 458.011383,341.717560 
	C460.208496,345.770355 462.491150,349.810364 465.130005,353.581604 
	C468.470673,358.355896 468.704742,363.383423 468.797150,369.071167 
	C468.891510,374.880280 465.920105,380.576660 468.567932,386.624908 
	C469.459778,388.662079 467.523254,391.942566 466.864227,394.651093 
	C466.603302,395.723358 465.735809,397.070923 466.107391,397.807098 
	C469.002228,403.542450 464.989319,408.546387 464.427277,413.876587 
	C464.267914,415.387909 461.701416,416.658112 460.213623,418.011139 
	C459.519196,418.642670 458.411530,419.059937 458.090210,419.825684 
	C453.233948,431.399109 444.915192,439.385101 433.067596,443.342072 
	C427.088135,445.339111 421.460449,443.482971 415.908966,440.567261 
	C408.110626,436.471497 403.200226,429.620636 399.469513,422.410828 
	C396.105804,415.910248 393.923706,408.307587 393.284180,401.010132 
	C392.454102,391.538544 393.029999,381.806061 394.244446,372.345551 
	C394.950287,366.847382 398.082886,361.658234 400.144531,356.338226 
	C400.717346,354.860107 401.686523,353.462158 401.928436,351.940704 
	C404.109070,338.227600 413.364929,329.326202 423.976166,322.390594 
	C429.055298,319.070801 436.491760,319.344360 442.878418,318.080811 
	C443.171783,318.022766 443.641052,318.854034 444.234680,319.610840 
z'
      />
      <path
        fill='#FFFFFF'
        opacity='0.000000'
        stroke='none'
        d='
M469.602234,259.117432 
	C467.506256,253.802170 465.696655,248.754425 463.887024,243.706680 
	C464.198456,243.512589 464.509857,243.318481 464.821289,243.124390 
	C465.991852,245.009521 466.939697,247.093369 468.395050,248.724411 
	C469.877106,250.385376 471.904633,251.547791 473.605286,253.029526 
	C474.754303,254.030640 475.552704,255.634964 476.848114,256.234436 
	C484.841827,259.933563 482.769318,266.587341 482.019409,272.914154 
	C481.883484,274.060669 482.000061,275.237091 482.000061,276.514893 
	C477.978790,275.366211 476.325714,272.683563 475.177521,268.833649 
	C474.170959,265.458496 471.708160,262.517670 469.602234,259.117432 
z'
      />
    </svg>
  )
}

export default Logo

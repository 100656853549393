import LargeTv from '../../components/LargeTV'

const MacPage = () => {
  return (
    <div className='vh-100 vw-100 flex items-center justify-center'>
      <LargeTv showMonitor={false} />
    </div>
  )
}

export default MacPage

import { useEffect } from 'react'
import Pico8 from 'react-pico-8'
import {
  Controls,
  Reset,
  Pause,
  Sound,
  Carts,
  Fullscreen,
} from 'react-pico-8/buttons'
import 'react-pico-8/styles.css'

const PicoPage = () => {
  useEffect(() => {
    const l = document.querySelector('button')
    l.click()
  }, [])

  return (
    <div>
      <Pico8
        src='./games/celeste/index.js'
        autoPlay={true}
        legacyButtons={false}
        hideCursor={false}
        center={true}
        blockKeys={false}
        usePointer={true}
        unpauseOnReset={true}
        placeholder='placeholder.png'
      >
        <Controls />
        <Reset />
        <Pause />
        <Sound />
        <Carts />
        <Fullscreen />
      </Pico8>
    </div>
  )
}

export default PicoPage

import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import FullscreenSlider from './pages/FullscreenSlider'
import ComingSoon from './pages/ComingSoon'
import VR from './pages/VR'
import MapPage from './pages/MapPage'
import TVPage from './pages/TV'
import TextToSpeach from './TextToSpeach'
import Picocade from './components/Picocade'
import Three from './pages/Three'
import Pepe from './pages/Pepe'
import Hugo from './pages/Hugo'
import MacPage from './pages/Mac'
import LG from './pages/LG'
import PicoPage from './pages/PicoPage'
import Robo from './pages/Robo'
import SkullBaby from './pages/Skullbaby'
import Full from './pages/Full/Full'
import CoolShit from './pages/CoolShit'
import WasHere from './pages/WasHere'
import CRTTree from './pages/CRTTree'
import CRTPalm from './pages/CRTPalm'
function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={TVPage} />
        <Route path='/mac' exact component={MacPage} />
        <Route path='/pico' exact component={PicoPage} />
        <Route path='/fs' exact component={LG} />
        <Route path='/bb' exact component={Picocade} />
        {/* <Route path='/three' exact component={Three} /> */}
        <Route path='/haiku-hugo' exact component={Hugo} />
        <Route path='/robo' exact component={Robo} />
        {/* <Route path='/pepe' exact component={Pepe} /> */}
        {/* <Route path='/skullbaby' exact component={SkullBaby} /> */}
        <Route path='/full' exact component={Full} />
        <Route path='/cs' exact component={CoolShit} />
        <Route path='/was-here' exact component={WasHere} />
        <Route path='/a' exact component={CRTTree} />
        <Route path='/palm' exact component={CRTPalm} />
      </Switch>
    </Router>
  )
}

export default App

import LargeTv from '../../components/LargeTV'

const LG = () => {
  return (
    <div className='vh-100 vw-100 flex items-center justify-center'>
      <LargeTv showMonitor={false} isLG={true} />
    </div>
  )
}

export default LG

// needs proper aspect ratiio images
// on click cycle through different huds
// clock
// crypto ticker
// crypyto ticker and clock
// stocks?
// sports scores
// surf heigts
// surf heigts and clocks - i'll work on others

import './index.scss'

const NewsTicker = ({ tickerText }) => {
  const aa = `${tickerText} ${tickerText} ${tickerText} ${tickerText}`

  return (
    <div className='ticker-container'>
      <div className='ticker-text mono text-lg-h3'>{tickerText}</div>
    </div>
  )
}

export default NewsTicker

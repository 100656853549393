const WasHere = () => {
  return (
    <div className="vh-100 vw-100 d-flex align-items-center justify-content-center">
      <img
        src="https://minters.s3.us-west-2.amazonaws.com/adz_was_here_001.gif"
        alt="Cool Shit"
      />
    </div>
  )
}

export default WasHere

import './index.scss'

const Picocade = () => {
  return (
    <div className='bg'>
      <div className='container'>
        <img
          src='https://minters.s3.us-west-2.amazonaws.com/adz/video-killed-the-radio-star.png'
          alt='tv'
        />
        <iframe src='https://kdata1.com/2020/11/22222421/' />
      </div>
    </div>
  )
}

export default Picocade

import { useLocation } from 'react-router-dom'

const Robo = () => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const text = queryParams.get('text')
  const repeat = queryParams.get('repeat')

  return (
    <div
      className={`vh-100 vw-100 d-flex align-items-center justify-content-center ${
        repeat ? 'bg-shit' : 'bg-shit-1'
      }`}
    >
      <p className='text-lg-h1 text-white'>{text}</p>
    </div>
  )
}

export default Robo

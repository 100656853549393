import { useState, useEffect, useRef } from 'react'
import './index.css'
import { numberParser } from '../../utils/numberParser'

const TV = ({ showMonitor = true }) => {
  const [channel, setChannel] = useState(1)
  const [loading, setLoading] = useState(true)
  const [imgCount, setImageCount] = useState(0)
  const maxChannels = 10

  // Logging the initial channel state
  useEffect(() => {
    console.log('Initial channel state:', channel)
  }, [])

  const searchParams = new URLSearchParams(window.location.search)
  const staticChannel = searchParams.get('channel')

  const tuneInToChannel = c => {
    console.log(`Tuning into static channel: ${staticChannel}`)
    setChannel(Number(staticChannel))
  }

  useEffect(() => {
    if (staticChannel) {
      tuneInToChannel(staticChannel)
      return
    }

    const interval = setInterval(() => {
      changeChannel()
    }, 10550)

    return () => clearInterval(interval)
  }, [channel, staticChannel])

  const imageUrls = [
    'https://minters.s3.us-west-2.amazonaws.com/adz/static.gif',
    'https://minters.s3.us-west-2.amazonaws.com/adz/5.gif',
    'https://minters.s3.us-west-2.amazonaws.com/adz/4.gif',
    'https://minters.s3.us-west-2.amazonaws.com/adz/1.gif',
    'https://minters.s3.us-west-2.amazonaws.com/adz/2.gif',
    'https://minters.s3.us-west-2.amazonaws.com/adz/3.gif',
    'https://minters.s3.us-west-2.amazonaws.com/adz/6.gif',
    'https://minters.s3.us-west-2.amazonaws.com/adz/7.gif',
    'https://minters.s3.us-west-2.amazonaws.com/adz/8.gif',
    'https://minters.s3.us-west-2.amazonaws.com/adz/9.gif',
    'https://minters.s3.us-west-2.amazonaws.com/adz/10.gif',
  ]

  useEffect(() => {
    console.log(
      '🤖 note... this is an older tv. Be patient when you see static'
    )
    const preloadImages = () => {
      let loadedImagesCount = 0
      imageUrls.forEach(url => {
        const img = new Image()
        img.src = url
        img.onload = () => {
          loadedImagesCount++
          console.log(`Loaded image ${loadedImagesCount}/${imageUrls.length}`)
          setImageCount(loadedImagesCount)
          if (loadedImagesCount === imageUrls.length) {
            setLoading(false) // All images are loaded
          }
        }
      })
    }

    preloadImages()
  }, [])

  const changeChannel = () => {
    const nextChannel = channel === maxChannels ? 1 : channel + 1
    console.log('Changing channel... Current channel:', channel)
    setChannel('static')
    setTimeout(() => {
      setChannel(nextChannel)
    }, 550)
  }

  return (
    <div className='h-100 w-100 bg-black d-flex align-items-center justify-content-center position-relative'>
      {loading ? (
        <div className='loading-screen player-2'>
          <p>Loading</p>
          <div className='loading-container'>
            {Array.from(new Array(imgCount)).map(() => (
              <div className='loading-pixel'></div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div
            className={`w-100 h-100 ${
              showMonitor ? 'tv-shit' : 'tv-shit-no-bg'
            }`}
            onClick={changeChannel}
          ></div>
          <div
            className={`w-100 h-100 tv-channel ${numberParser(channel)} ${
              showMonitor ? '' : 'no-monitor'
            }`}
          ></div>
        </>
      )}
    </div>
  )
}

export default TV

import React, { Suspense, useRef, useState } from 'react'
import Header from '../../components/Header'
import Hero from '../../components/Hero'
import LargeTextSection from '../../components/LargeTextSection'
import Skills from '../../components/Skills'
import Contact from '../../components/Contact'

import '../../data/projects'
import Projects from '../../components/Projects'
import { Canvas, useFrame } from '@react-three/fiber'
import Box from '../../components/3d/Box'
import { PerspectiveCamera, useGLTF } from '@react-three/drei'

export function MyFallbackComponent() {
  return (
    <div>
      <h2>Loading...</h2>
      <p>Please wait while we load the 3D model.</p>
    </div>
  )
}

export const Skatie = ({ nodes, materials, group }) => {
  const groupRef = useRef()
  const [isKicking, setIsKicking] = useState(false)

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime()

    if (isKicking) {
      if (groupRef.current.rotation.x > -1.5) {
        groupRef.current.rotation.x -= 0.1
      }
    }
    // else {
    //   groupRef.current.rotation.x = Math.sin(elapsedTime) * 0.2
    // }

    // groupRef.current.rotation.z = elapsedTime * 0.25
    // groupRef.current.rotation.y = elapsedTime * 0.25
  })

  const handleKick = () => {
    setIsKicking(true)

    setTimeout(() => {
      setIsKicking(false)
    }, 1000)
  }

  return (
    <group ref={group} dispose={null}>
      <group
        position={[0, 0, 0]}
        rotation={[Math.PI / 2, 0, Math.PI * 1.1]}
        scale={1.15}
        ref={groupRef}
      >
        <mesh
          geometry={nodes.Mesh012.geometry}
          material={materials.SkateBoardSub}
        />
        <mesh geometry={nodes.Mesh012_1.geometry} material={materials.METAL} />
        <mesh geometry={nodes.Mesh012_2.geometry} material={materials.GRIP} />
        <mesh
          geometry={nodes.BOTTOM_DESIGN.geometry}
          material={materials.Material}
          position={[0, 0.32, 0.41]}
        />
        <mesh
          geometry={nodes.Wheel01.geometry}
          material={materials.WHEELS}
          position={[0.3, -4.14, 7.54]}
        />
        <mesh
          geometry={nodes.Wheel02.geometry}
          material={materials.WHEELS}
          position={[0.3, -4.14, 7.54]}
        />
        <mesh
          geometry={nodes.Wheel03.geometry}
          material={materials.WHEELS}
          position={[1.36, -0.5, -3.93]}
        />
        <mesh
          geometry={nodes.Wheel04.geometry}
          material={materials.WHEELS}
          position={[0.3, -4.14, 7.54]}
        />
      </group>
    </group>
  )
}

const Home = () => {
  return (
    <div className='bg-white vw-100 vh-100' style={{ overflow: 'scroll' }}>
      <div className='hero-bg-img'>
        <Header />
        <Hero />
        <LargeTextSection
          text={`I'm a Full-stack Engineer who enjoys solving unique problems and reaching outside the box for solutions.  I bring a unique eye and expertise to each project I join, and enjoy the challenges each project brings.`}
          height='400px'
          link='/download'
        />
        <Projects />
        <Skills />
        <Contact />
        <div className='w-100 d-flex flex-column align-items-center bg-white'>
          <h1>hello there</h1>
          <div className='w-25'>
            <video autoPlay loop muted className='w-100'>
              <source src='images/mp4/0007.mp4' type='video/mp4' />
            </video>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Home

// next step - try to get it going on a separate page because of this shitty

const CRTTree = () => {
  const text = 'adz was here'

  return (
    <div
      className={`vh-100 vw-100 d-flex align-items-center justify-content-center static-palms`}
    >
      <div className='bg-black w-50 d-flex align-items-center justify-content-center px-5 py-3  blinking-text'>
        <p className='text-lg-h1 text-white player-2 text-center d-flex align-items-center justify-content-center '>
          {text ? text : null}
        </p>
      </div>
    </div>
  )
}

export default CRTTree
